/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, useRef, useState } from "react";
import { RightNavigation, LeftNavigation } from "../../../icons";
import { Hero } from "../../distribution/hero";
import { Slide } from "../../../types/slide";

interface SliderProps {
  slides: Slide[];
}

export function Slider({ slides }: SliderProps) {
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState(true);
  const autoPlayRef = useRef(null);

  useEffect(() => {
    autoPlayRef.current = nextSlide;
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };

    const interval = active && setInterval(play, 5 * 1000);
    return () => clearInterval(interval);
  }, [active]);

  const nextSlide = () => {
    setIndex((c) => {
      if (c === slides.length - 1) {
        return 0;
      }
      return c + 1;
    });
  };
  const prevSlide = () => {
    setIndex((c) => {
      if (c === 0) {
        return slides.length - 1;
      }
      return c - 1;
    });
  };
  return (
    <div sx={{ position: "relative", bg: "footer" }}>
      <div sx={{ width: "100%", overflow: "hidden" }}>
        <div
          sx={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            overflow: "hidden",
            position: "relative",
            minHeight: ["calc(100vw + 208px)", "512px"],
            pb: [null, "56.51%", null, "35.55%"],
          }}
        >
          {slides.map((slide, i) => {
            const promoText =
              slide.childContentfulServiceCategoryPromoTextTextNode?.promoText;
            if (slide.metaImage)
              return (
                <div
                  key={slide.title}
                  sx={{
                    width: "100%",
                    height: "100%",
                    visibility: index === i ? "visible" : "hidden",
                    opacity: index === i ? "1" : "0",
                    transition: "400ms linear",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                >
                  <Hero
                    image={slide.metaImage.fluid}
                    title={slide.title}
                    description={promoText}
                    slug={slide.slug}
                  />
                </div>
              );
          })}
        </div>
      </div>
      <button
        aria-label="Slider navigation"
        onClick={() => {
          nextSlide();
          setActive(false);
        }}
        sx={{
          position: "absolute",
          zIndex: 100,
          mt: ["-48px", "-24px"],
          overflow: "hidden",
          top: "50%",
          right: [0, null, null, null, 5],
          bg: "transparent",
          border: "none",
          outline: "none",
          color: "primary",
          cursor: "pointer",
        }}
      >
        <RightNavigation />
      </button>
      <button
        aria-label="Slider navigation"
        onClick={() => {
          prevSlide();
          setActive(false);
        }}
        sx={{
          position: "absolute",
          zIndex: 100,
          mt: ["-48px", "-24px"],
          overflow: "hidden",
          top: "50%",
          left: [0, null, null, null, 5],
          bg: "transparent",
          border: "none",
          outline: "none",
          color: "primary",
          cursor: "pointer",
        }}
      >
        <LeftNavigation />
      </button>
    </div>
  );
}
