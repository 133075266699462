/** @jsx jsx */
import { Heading, jsx } from "theme-ui";
import Img from "gatsby-image";
import { Partner } from "../../types/partner";

interface PartnersProps {
  title: string;
  partners: Partner[];
}

export function Partners({ partners, title }: PartnersProps) {
  return (
    <div sx={{ bg: "footer", px: [2, 5], py: [4, null, null, 5] }}>
      <Heading
        as="h2"
        sx={{
          fontSize: [4, null, null, 6],
          color: "header",
          textAlign: "center",
          mb: [4, null, null, 5],
        }}
      >
        {title}
      </Heading>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr 1fr", "repeat(4,1fr)", "repeat(4, 1fr)"],
          maxWidth: ["800px", null, null, null, "1280px"],
          mx: "auto",
          gap: 4,
          p: 2,
        }}
      >
        {partners.map((partner) => (
          <a
            rel="noopener"
            href={partner.links}
            target="__blank"
            key={partner.id}
            sx={{ p: 1, bg: "white", borderRadius: "16px" }}
          >
            <span sx={{ display: "none" }}>{partner.title}</span>

            <Img fluid={partner.image.fluid} alt={partner.image.description} />
          </a>
        ))}
      </div>
    </div>
  );
}
