/**@jsx jsx */
import { graphql, PageProps } from "gatsby";
import { jsx } from "theme-ui";
import { Layout } from "../components/layout/main-layout";
import { useContext, useEffect } from "react";
import { Document } from "@contentful/rich-text-types";

import { AppContext } from "../context/app-context";
import { Slider } from "../components/common/slider/slider";
import { Services } from "../components/distribution/services";
import { Partners } from "../components/distribution/partners";
import { BlogPosts } from "../components/distribution/blog-posts";
import { Indicators } from "../components/distribution/indicators";
import { SliderClients } from "../components/common/slider/slider-clients";
import { Seo } from "../components/seo/seo";

interface PageContext {
  slug: string;
  locale: "es-PE" | "en-US";
}

interface PageContent {
  id: string;
  spaceId: string;
  title: string;
  slug: string;
  order: number;
  content: {
    json: Document;
  };
}

export default function PageTemplate({
  data,
  location,
  pageContext,
}: PageProps<any, PageContext>) {
  const lang = pageContext.locale === "es-PE" ? "es" : "en";

  /* LOCALES SET UP*/
  const { locale, setLocale } = useContext(AppContext);

  useEffect(() => {
    setLocale(pageContext.locale);
  }, []);

  /*PAGE CONSTANTS */
  const page = data.contentfulPage;
  const { title, image } = page;
  /*PROMO SLIDES CONSTANTS */
  const slides = data.promoService.nodes;
  /*SERVICE COMPONENT*/
  const services = data.service.nodes;
  const filteredServices = services.filter(
    (service) => service.contentfulparent === null
  );
  /* MARCAS */

  const marcas = data.marcas?.nodes;

  console.log({ marcas });

  /*CLIENT COMPONENT */
  const clients = data.clients.nodes;
  /*Partner COMPONENT */
  const partners = data.partners.nodes;
  /*BLOG COMPONENT */
  const blogs = data.blogs.nodes;
  const indicators = data.indicators.nodes;

  return (
    <Layout>
      <Seo
        title={title}
        image={image && { src: image.fluid.src, height: 300, width: 300 }}
        lang={lang}
      />
      <Slider slides={slides} />

      <Indicators indicators={indicators} />
      <Services services={filteredServices} />
      <SliderClients
        clients={clients}
        title={locale === "es-PE" ? "Nuestros Clientes" : "Our Clients"}
      />

      <Partners
        partners={partners}
        title={locale === "es-PE" ? "Socios" : "Partners"}
      />
      <SliderClients
        clients={marcas}
        title={locale === "es-PE" ? "Marcas" : "Brands"}
      />
      <BlogPosts
        blogs={blogs}
        title={locale === "es-PE" ? "Últimas Publicaciones" : "Last News"}
      />
    </Layout>
  );
}

export const query = graphql`
  query($locale: String, $slug: String) {
    contentfulPage(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      image {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    service: allContentfulServiceCategory(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        title
        slug
        id
        icon
        node_locale
        contentfulparent {
          title
        }
        service_category {
          title
          slug
          id
          services {
            id
          }
        }
        services {
          title
          slug
          id
        }
      }
    }
    promoService: allContentfulServiceCategory(
      filter: { node_locale: { eq: $locale }, featured: { eq: true } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        title
        slug
        metaImage {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        childContentfulServiceCategoryPromoTextTextNode {
          promoText
        }
      }
    }
    clients: allContentfulClient(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        title
        link
        image {
          fluid(quality: 80, maxWidth: 250) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
      }
    }
    partners: allContentfulPartners(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        title
        links
        image {
          fluid(quality: 80, maxWidth: 250) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
      }
    }
    blogs: allContentfulBlog(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: updatedAt, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        metaImage {
          fluid(maxWidth: 512) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        slug
        tag
        createdAt(locale: $locale)
      }
    }
    indicators: allContentfulIndicators(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        key
        value
        position
      }
    }
    marcas: allContentfulMarcas(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        link
        title
        node_locale
        image {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
