/**@jsx jsx */
import { Fragment, useEffect, useState } from "react";
import { Heading, jsx } from "theme-ui";

const easeOutQuad = (t) => t * (2 - t);
const frameDuration = 1000 / 60;

function Indicator({ title, number, position }) {
  const indicatorNumber = number > 100000 ? number / 1000 : number;
  const indicatorUnit = number > 100000 ? "k" : "";
  const countTo = parseInt(indicatorNumber);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let mounted = true;
    const timeOut = () => {
      let frame = 0;
      const totalFrames = Math.round(500 / frameDuration);
      if (mounted) {
        const counter = setInterval(() => {
          frame++;
          const progress = easeOutQuad(frame / totalFrames);
          setCount(countTo * progress);

          if (frame === totalFrames || !mounted) {
            clearInterval(counter);
          }
        }, frameDuration);
      }
    };
    timeOut();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Fragment>
      <div
        sx={{
          textAlign: "center",
          display: "grid",
          placeItems: "center",
          color: "primary",
          gridColumn: [
            null,
            position === 1 ? "1/2" : position === 2 ? "2/3" : "3/4",
          ],
          gridRow: [null, "1/2"],
        }}
      >
        <Heading sx={{ fontSize: [5] }}>
          {" "}
          {`+${Math.floor(count)}${indicatorUnit}`}
        </Heading>
      </div>
      <Heading
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          color: "header",
          gridColumn: [
            null,
            position === 1 ? "1/2" : position === 2 ? "2/3" : "3/4",
          ],
          gridRow: [null, "2/3"],
          fontSize: [3],
          mx: position === 2 && 5,
          mb: 3,
        }}
      >
        {title}
      </Heading>
    </Fragment>
  );
}

export function Indicators({ indicators }) {
  return (
    <div
      sx={{
        position: "relative",
        p: 3,
        height: [null, null, "184px"],
        bg: "footer",
      }}
    >
      <div
        sx={{
          position: [null, "static", "absolute"],
          bg: "white",
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(3,min-content)"],
          justifyContent: "center",
          placeItems: "center",
          gap: [0, 3],
          pt: [4],
          px: [3],
          top: [null, null, "-56px", null, "-72px"],
          zIndex: 200,
          ml: [null, null, "-400px"],
          width: [null, "100%", "800px"],
          left: [null, null, "50%"],
          pb: [4],
          minHeight: "256px",
          borderRadius: ["32px"],
        }}
      >
        {indicators.map((indicator, i) => (
          <Indicator
            title={indicator.key}
            number={indicator.value}
            position={indicator.position}
            key={i}
          />
        ))}
      </div>
    </div>
  );
}
