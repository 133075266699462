/** @jsx jsx */
import { Link, navigate } from "gatsby";
import { useContext } from "react";
import { Heading, jsx } from "theme-ui";
import { AppContext } from "../../context/app-context";
import { LocationContext } from "../../context/location-context";
import {
  Abap,
  Basis,
  Capacitaciones,
  Cloud,
  HelpDesk,
  SapBusiness,
  Sapr3,
  SapS4,
  Website,
} from "../../icons";

export function Services({ services }) {
  const { locale, setCategoryId, setServiceId } = useContext(AppContext);
  const { serviceRef } = useContext(LocationContext);
  const servicesPath = locale === "es-PE" ? "servicios" : "services";
  return (
    <div
      id="services"
      ref={serviceRef}
      sx={{
        bg: ["footer"],
        borderRadius: [null, null, null, null, "64px"],
        position: "relative",
        overflow: "hidden",
        py: [4, 5],
      }}
    >
      <Heading variant="text.title">
        {locale === "es-PE" ? "Nuestros Servicios" : "Our Services"}
      </Heading>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: ["center"],
          gap: [2, null, null, 4],
          maxWidth: [null, null, "720px", "800px", "1160px"],
          mx: ["auto"],
        }}
      >
        {services.map((service) => (
          <div
            key={service.id}
            sx={{
              width: ["45%", "126px", null, null, "200px"],
              height: ["100px", "126px", null, null, "200px"],
              position: "relative",
              bg: "white",
              borderRadius: ["16px", "40px", "40px", null, "62px"],
              color: "header",
              boxShadow: [
                "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
                "none",
              ],
              "&:hover": {
                bg: "primary",

                color: "background",
                boxShadow:
                  "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
              },
            }}
          >
            <Link
              to={`${servicesPath}/${service.slug}`}
              onClick={() => {
                if (service.service_category === null) {
                  setCategoryId("1");
                  setServiceId(service.services[0].id);
                } else {
                  setCategoryId(service.service_category[0].id);
                  setServiceId(service.service_category[0].services[0].id);
                }
              }}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                p: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                textDecoration: "none",
                color: "inherit",
                fontSize: [0, null, 0, null, 2],
                textTransform: "uppercase",
                fontWeight: "heading",
              }}
            >
              <span
                sx={{
                  display: ["block", null, null, "block"],
                  height: ["40px", null, "64px", null, "96px"],
                }}
              >
                {service.icon === "sapr3" && <Sapr3 />}
                {service.icon === "saps4" && <SapS4 />}
                {service.icon === "helpdesk" && <HelpDesk />}
                {service.icon === "basis" && <Basis />}
                {service.icon === "cloud" && <Cloud />}
                {service.icon === "abap" && <Abap />}
                {service.icon === "website" && <Website />}
                {service.icon === "sapbusiness" && <SapBusiness />}
                {service.icon === "capacitaciones" && <Capacitaciones />}
              </span>
              <span sx={{ mt: 2 }}>
                {" "}
                {service.title === "Capacitaciones"
                  ? "Training"
                  : service.title}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
