/** @jsx jsx */
import { Heading, jsx } from "theme-ui";
import { useState, useEffect, useRef } from "react";
import Image from "gatsby-image";

import { RightNavigation, LeftNavigation } from "../../../icons";
import { Client } from "../../../types/client";

interface SliderProps {
  clients: Client[];
  title: string;
}

export function SliderClients({ clients, title }: SliderProps) {
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState(true);
  const [slides, setSlides] = useState(null);
  const autoPlayRef = useRef(null);

  useEffect(() => {
    autoPlayRef.current = nextSlide;
    if (clients.length > 0) {
      window && window.innerWidth < 600
        ? setSlides(Math.ceil(clients.length / 4))
        : window.innerWidth < 1920
        ? setSlides(Math.ceil(clients.length / 3))
        : setSlides(Math.ceil(clients.length / 5));
    }
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };
    const interval = active && setInterval(play, 5 * 1000);
    return () => clearInterval(interval);
  }, [active]);

  const nextSlide = () => {
    setIndex((c) => {
      if (c >= slides - 1) {
        return 0;
      }
      return c + 1;
    });
  };
  const prevSlide = () => {
    setIndex((c) => {
      if (c === 0) {
        return slides - 1;
      }
      return c - 1;
    });
  };
  return (
    <div sx={{ position: "relative", py: 4 }}>
      <Heading sx={{ variant: "text.title", m: 0 }}>{title}</Heading>
      <div sx={{ position: "relative" }}>
        <div
          sx={{
            width: "85vw",
            overflow: "hidden",
            maxWidth: ["800px", null, null, null, "1280px"],
            p: 3,
            mx: "auto",
          }}
        >
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: [
                "repeat(2,1fr)",
                "repeat(3,1fr)",
                null,
                null,
                "repeat(5, 1fr)",
              ],
              gridAutoRows: ["96px", "192px"],
              height: ["192px", "192px"],
              gap: 3,
            }}
          >
            {clients.map((client, i) => {
              return (
                <a
                  rel="noopener"
                  href={client.link || "https://google.com"}
                  target="_blank"
                  key={client.title}
                  sx={{
                    alignSelf: "center",
                    px: 2,
                    gridColumn: [
                      `${(i % 2) + 1}/${(i % 2) + 2}`,
                      `${(i % 3) + 1}/${(i % 3) + 2}`,
                      null,
                      null,
                      `${(i % 5) + 1}/${(i % 5) + 2}`,
                    ],
                    gridRow: [`${i % 4 === 0 || i % 4 === 1 ? 1 : 2}/2`, "1/2"],
                    visibility: [
                      index <= Math.floor(i / 4) &&
                      index + 1 > Math.floor(i / 4)
                        ? "visible"
                        : "hidden",
                      index <= Math.floor(i / 3) &&
                      index + 1 > Math.floor(i / 3)
                        ? "visible"
                        : "hidden",
                      null,
                      null,
                      index <= Math.floor(i / 5) &&
                      index + 1 > Math.floor(i / 5)
                        ? "visible"
                        : "hidden",
                    ],
                    opacity: [
                      index <= Math.floor(i / 4) &&
                      index + 1 > Math.floor(i / 4)
                        ? "1"
                        : "0",
                      index <= Math.floor(i / 3) &&
                      index + 1 > Math.floor(i / 3)
                        ? "1"
                        : "0",
                      null,
                      null,
                      index <= Math.floor(i / 5) &&
                      index + 1 > Math.floor(i / 5)
                        ? "1"
                        : "0",
                    ],
                    transitionDuration: "400ms",
                    bg: "background",
                    borderRadius: "56px",
                    p: 2,
                    overflow: "hidden",
                  }}
                >
                  <span sx={{ display: "none" }}>{client.title}</span>
                  <Image fluid={client.image.fluid} />
                </a>
              );
            })}
          </div>
        </div>
        <button
          aria-label="Slider client navigation"
          onClick={() => {
            nextSlide();
            setActive(false);
          }}
          sx={{
            position: "absolute",
            zIndex: 100,
            mt: ["-24px", "-24px"],
            overflow: "hidden",
            top: "50%",
            right: [0, null, null, null, 5],
            bg: "transparent",
            border: "none",
            outline: "none",
            color: "primary",
            cursor: "pointer",
          }}
        >
          <RightNavigation />
        </button>
        <button
          aria-label="Slider client navigation"
          onClick={() => {
            prevSlide();
            setActive(false);
          }}
          sx={{
            position: "absolute",
            zIndex: 100,
            mt: ["-24px", "-24px"],
            overflow: "hidden",
            top: "50%",
            left: [0, null, null, null, 5],
            bg: "transparent",
            border: "none",
            outline: "none",
            color: "primary",
            cursor: "pointer",
          }}
        >
          <LeftNavigation />
        </button>
      </div>
    </div>
  );
}
